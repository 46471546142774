import { CurrencyService } from '../../_shared-core/service/currency.service'
import { DateService } from '../../_shared-core/service/date.service'
import { AsiakasJaettuService } from './asiakas-jaettu.service'

import { AlvIlmoitus, KirjauksienLuontityyppi, AlvIlmoituksenTunnistetiedot, Kirjaus, KirjanpitotilinAlvTyyppi, KirjaukseenLiitettyjenTiedostojenSivut, KirjaukseenLiitetynTiedostonSivu, KirjaukseenLiitetynTiedostonSivunTyyppi, Kirjanpitotili, Raportointikirjausrivi, MyyntiAlvt, OstoAlvt, Raportointikirjaus, FlatattuHierarkia, KirjattavaLasku, PoistotilienMuutostiedot, Kirjausrivi, RaporttiPaakirjaAccountRow, RaporttiTuloslaskelmaAccountRow, RaporttiTaselaskelmaAccountRow, KirjanpitotilinPoistotiedot } from '../model/kirjanpito'
import { AsiakkaanMaksutapa, Asiakas, AlvIlmoitusjakso, Tilikausi, AlvIlmoitusjaksoAikajaksolla } from '../model/asiakas'
import { LocalMonth, LocalDate, PaivamaaraAikavali, TuettuKieli } from '../../_shared-core/model/common'
import { Lasku, LaskunAlv, LaskunTyyppi } from '../../_jaettu/model/lasku'
import { FirestoreTosite, FirestoreTositteenAlkuperainenTiedosto } from '../../_jaettu/model/tosite'
import { PyhapaivatService } from '../../_shared-core/service/pyhapaiva.service'
import { HierarkiaKirjanpitotili, TilikarttaJaettuService } from './tilikartta-jaettu.service'
import { AlvBaseService } from '../../_jaettu/service/alv-base.service'
import { Kayttaja } from '../../_jaettu/model/kayttaja'
import { TranslationService } from '../../_jaettu/service/translation.service'

export interface AlvIlmoituksenJaksotiedot {
  jakso: AlvIlmoitusjakso
  aikavali: PaivamaaraAikavali
}

export interface RaporttikirjauksistaLasketutAlvIlmoituksenSummat {
  alv255: number
  alv24: number
  alv14: number
  alv10: number
  taveu: number
  palveu: number
  taveieu: number
  rak: number
  vahennettava: number
  nolla: number
  tavmyynniteu: number
  palvmyynniteu: number
  tavostoeu: number
  palvostoeu: number
  tavulkeu: number
  rakmetmyynti: number
  rakmetosto: number
  tili2939Kokonaan: number
  tili1763Kokonaan: number
}

class AlvService extends AlvBaseService {
  public override async annaLaskutyypinAlvt(laskunTyyppi: LaskunTyyppi, maaKoodi: string): Promise<LaskunAlv[]> {
    throw new Error('This implementation is only used to access puraEtamyyntiTunnisteTaiNull')
  }
}

export class KirjanpitoJaettuService {

  private _defaultYksityisottoTili = '2341'
  // private _kaikkiAutocompleteKommentitFi: string[] = [
  //   'Tosite puuttuu',
  //   'Tarvitaan läsnäolijoiden nimet',
  //   'Tosite on liian epäselvä'
  // ]
  // private _kaikkiAutocompleteKommentitEn: string[] = [
  //   'Receipt missing',
  //   'Participant names are needed',
  //   'Receipt unreadable'
  // ]
  // annaKaikkiAutocompleteKommentit(kieli: TuettuKieli): string[] {
  //   if (kieli === 'en') {
  //     return this._kaikkiAutocompleteKommentitEn.slice()
  //   }
  //   return this._kaikkiAutocompleteKommentitFi.slice()
  // }

  private _alvService: AlvService
  constructor(
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _pyhapaivatService: PyhapaivatService,
    private _translationService: TranslationService,
    private _tilikarttaJaettuService: TilikarttaJaettuService
  ) {
    this._alvService = new AlvService(this._currencyService, this._dateService)
  }

  annaLaskuKirjattavastaLaskusta(kirjattava: Pick<KirjattavaLasku, 'lasku'>): Lasku | null {
    if (!kirjattava || !kirjattava.lasku) {
      return null
    }
    if (Array.isArray(kirjattava.lasku)) {
      if (kirjattava.lasku.length > 0) {
        return kirjattava.lasku[kirjattava.lasku.length - 1]
      }
      return null
    }
    return kirjattava.lasku ?? null
  }

  annaYksityisottoTili(maksutavat: AsiakkaanMaksutapa[], tallentaja: Kayttaja['avain']) {
    const luodaanTositteistaMaksutavat: AsiakkaanMaksutapa[] = maksutavat.filter(mt => mt.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE)
    if (!luodaanTositteistaMaksutavat.length) {
      return this._defaultYksityisottoTili
    }
    const tallentajanOmallaRahallaTilit = luodaanTositteistaMaksutavat.filter(mt => mt.omallaRahallaUid && mt.omallaRahallaUid === tallentaja)

    if (tallentajanOmallaRahallaTilit.length) {
      return tallentajanOmallaRahallaTilit[0].oletusVastatili
    }

    if (luodaanTositteistaMaksutavat.length === 1) {
      return luodaanTositteistaMaksutavat[0].oletusVastatili
    }
    return this._defaultYksityisottoTili
  }

  onkoAlvTilinumero(tilinumero: string): boolean {
    return tilinumero === '1763' || tilinumero === '2939' || tilinumero === '2946' // TODO: LOPUT TILINUMEROT
  }

  annaMaksettavaTaiPalautettavaVero(ilmoitus: AlvIlmoitus): number {
    const maksettavaVeroYhteensa = ilmoitus.alv255 + ilmoitus.alv24 + ilmoitus.alv14 + ilmoitus.alv10 + ilmoitus.taveu + ilmoitus.palveu + ilmoitus.taveieu + ilmoitus.rak
    const huojennus = ilmoitus.arhSyotetty?.huojennus || 0
    const maksettavaTaiPalautettavaVero = this._currencyService.roundHalfUp(maksettavaVeroYhteensa - ilmoitus.vahennettava - huojennus, 2)
    return maksettavaTaiPalautettavaVero
  }

  onkoMaksutapaSahkoinenTiliote(maksutapa: AsiakkaanMaksutapa): boolean {
    return (
      maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE ||
      maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE ||
      maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE
    )
  }
  annaAlvilmoituksenTunnistetiedot(jaksotiedot: AlvIlmoituksenJaksotiedot): AlvIlmoituksenTunnistetiedot {

    if (!jaksotiedot) {
      return null
    }
    // const asDate = this._dateService.localDateToDate({ year: valittuKuukausi.year, month: valittuKuukausi.month, day: 15 })
    // const voimassaOlevaAlvilmoitusjakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, asDate)

    // if (!alvIlmoitusjakso) {
    //   throw new Error('Asiakkaalla ' + asiakas.avain + ' ei ole voimassa olevaa alv-ilmoitusjaksoa päivämäärällä ' + JSON.stringify(valittuKuukausi) + '.')
    // }

    const alvIlmoitusjakso = jaksotiedot.jakso
    const valittuKuukausi = jaksotiedot.aikavali.end

    if (alvIlmoitusjakso === AlvIlmoitusjakso.KK1) {
      return { vuosi: valittuKuukausi.year, jarjestys: valittuKuukausi.month, alvIlmoitusjakso: AlvIlmoitusjakso.KK1 }
    } else if (alvIlmoitusjakso === AlvIlmoitusjakso.KK3) {

      const month = valittuKuukausi.month

      // TODO: Could be expressed as Math.round(ilmoitus.endMonth.month / 4) also...
      // On the other hand then the error handling is not as precise.

      if (month === 1 || month === 2 || month === 3) {
        return { vuosi: valittuKuukausi.year, jarjestys: 1, alvIlmoitusjakso: AlvIlmoitusjakso.KK3 }
      } else if (month === 4 || month === 5 || month === 6) {
        return { vuosi: valittuKuukausi.year, jarjestys: 2, alvIlmoitusjakso: AlvIlmoitusjakso.KK3 }
      } else if (month === 7 || month === 8 || month === 9) {
        return { vuosi: valittuKuukausi.year, jarjestys: 3, alvIlmoitusjakso: AlvIlmoitusjakso.KK3 }
      } else if (month === 10 || month === 11 || month === 12) {
        return { vuosi: valittuKuukausi.year, jarjestys: 4, alvIlmoitusjakso: AlvIlmoitusjakso.KK3 }
      } else {
        throw new Error('Unknown month / date ' + JSON.stringify(valittuKuukausi))
      }

    } else if (alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {
      return { vuosi: valittuKuukausi.year, jarjestys: 1, alvIlmoitusjakso: AlvIlmoitusjakso.KK12 }
    }

    return null

  }
  annaTunnistetiedotString(tunnistetiedot: AlvIlmoituksenTunnistetiedot, kieli: TuettuKieli): string {
    if (tunnistetiedot) {
      if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK1) {
        return tunnistetiedot.jarjestys + ' / ' + tunnistetiedot.vuosi + ' (' + this._translationService.lokalisoi('yleiset.kuukausi', kieli) + ')'
      } else if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK3) {
        return tunnistetiedot.jarjestys + ' / ' + tunnistetiedot.vuosi + ' (' + this._translationService.lokalisoi('yleiset.neljannesvuosi', kieli) + ')'
      } else if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {
        return tunnistetiedot.jarjestys + ' / ' + tunnistetiedot.vuosi + ' (' + this._translationService.lokalisoi('yleiset.vuosi', kieli) + ')'
      }
    }
    return ''
  }
  annaTunnistetiedotFileNameString(tunnistetiedot: AlvIlmoituksenTunnistetiedot, kieli: TuettuKieli): string {
    if (tunnistetiedot) {
      if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK1) {
        return tunnistetiedot.vuosi + '_' + tunnistetiedot.jarjestys + '_' + '(' + this._translationService.lokalisoi('yleiset.kuukausi', kieli) + ')'
      } else if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK3) {
        return tunnistetiedot.vuosi + '_' + tunnistetiedot.jarjestys + '_' + '(' + this._translationService.lokalisoi('yleiset.neljannesvuosi', kieli) + ')'
      } else if (tunnistetiedot.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {
        return tunnistetiedot.vuosi + '_' + tunnistetiedot.jarjestys + '_' + '(' + this._translationService.lokalisoi('yleiset.vuosi', kieli) + ')'
      }
    }
    return ''
  }
  laskeAlarajahuojennus(tilikausi: Tilikausi, huojennukseenOikeuttavaVero: number, suhteutettuLiikevaihto: number): number {

    if (!huojennukseenOikeuttavaVero || !suhteutettuLiikevaihto || huojennukseenOikeuttavaVero < 0 || suhteutettuLiikevaihto < 0) {
      return 0
    }

    if (tilikausi.alkaa.year < 2021) { // For year 2020 and before
      const huojennus = huojennukseenOikeuttavaVero - (suhteutettuLiikevaihto - 10000) * huojennukseenOikeuttavaVero / 20000
      const huojennusMaxMaksettuVero = Math.min(huojennus, huojennukseenOikeuttavaVero)
      const huojennusMinZero = Math.max(huojennusMaxMaksettuVero, 0)
      return this._currencyService.roundHalfUp(huojennusMinZero, 2)
    } else {
      const huojennus = huojennukseenOikeuttavaVero - (suhteutettuLiikevaihto - 15000) * huojennukseenOikeuttavaVero / 15000
      const huojennusMaxMaksettuVero = Math.min(huojennus, huojennukseenOikeuttavaVero)
      const huojennusMinZero = Math.max(huojennusMaxMaksettuVero, 0)
      return this._currencyService.roundHalfUp(huojennusMinZero, 2)
    }

    // throw new Error('Alarajahuojennuksen laskentaa vuodelle ' + verovuosi + ' ei ole vielä implementoitu')

  }

  annaRaportointikirjaustenVertailuFunktio(): (a: Raportointikirjaus, b: Raportointikirjaus) => number {
    return (a: Raportointikirjaus, b: Raportointikirjaus): number => {
      // Ordering priority: date > maksutapa > kirjausnumero index
      if (a.p !== b.p) {
        return a.p - b.p
      }
      if (a.t !== b.t) {
        return a.t.localeCompare(b.t)
      }
      // The kirjausnumero is of type maksutavan tunniste (t) tyymm-, so it's always .t.length + 5
      if (a.k && b.k) {
        return Number(a.k.substring(a.t.length + 5)) - Number(b.k.substring(b.t.length + 5))
      }
      if (a.k) {
        return -1
      }
      if (b.k) {
        return 1
      }
      return 0
    }
  }

  annaKirjaustenVertailuFunktio(): (a: Kirjaus, b: Kirjaus) => number {
    return (a: Kirjaus, b: Kirjaus): number => {
      // Ordering priority: date > maksutapa > kirjausnumero index
      if (a.p !== b.p) {
        return a.p - b.p
      }
      if (a.maksutavanTunniste !== b.maksutavanTunniste) {
        return a.maksutavanTunniste.localeCompare(b.maksutavanTunniste)
      }
      if (a.kirjausnumero && b.kirjausnumero) {
        // The kirjausnumero is of type maksutavan tunniste (t) tyymm-, so it's always .maksutavanTunniste.length + 5
        return Number(a.kirjausnumero.substring(a.maksutavanTunniste.length + 5)) - Number(b.kirjausnumero.substring(b.maksutavanTunniste.length + 5))
      }
      if (a.kirjausLuotu && b.kirjausLuotu) {
        return a.kirjausLuotu.toMillis() - b.kirjausLuotu.toMillis()
      }
      if (a.kirjausLuotu) {
        return -1
      }
      if (b.kirjausLuotu) {
        return 1
      }
      return 0
    }
  }

  annaKuukaudenAikanaPaattyvaTilikausi(tilikaudet: Tilikausi[], valittuKuukausi: LocalMonth): Tilikausi {
    if (!valittuKuukausi || !tilikaudet) { return null }
    for (const tilikausi of tilikaudet) {
      if (this._dateService.compareLocalMonths(tilikausi.loppuu, '==', valittuKuukausi)) {
        return tilikausi
      }
    }
    return null
  }
  annaTilikausiPaivamaaravalilleKuukaudenTarkkuudella(tilikaudet: Tilikausi[], alkaa: LocalDate, loppuu: LocalDate): Tilikausi {
    return tilikaudet?.find(tlk => this._dateService.onkoLocalMonthKahdenValissa(alkaa, tlk.alkaa, tlk.loppuu) && this._dateService.onkoLocalMonthKahdenValissa(loppuu, tlk.alkaa, tlk.loppuu))
  }
  // annaTilikausiPaivamaaravalille(tilikaudet: Tilikausi[], alkaa: LocalDate, loppuu: LocalDate): Tilikausi {
  //   return tilikaudet?.find(tlk => this._dateService.onkoLocalDateKahdenValissa(alkaa, tlk.alkaa, tlk.loppuu) && this._dateService.onkoLocalDateKahdenValissa(loppuu, tlk.alkaa, tlk.loppuu))
  // }
  annaTilikausiPaivalle(tilikaudet: Tilikausi[], paiva: LocalDate): Tilikausi {
    return tilikaudet?.find(tilikausi => this._dateService.onkoLocalDateKahdenValissa(paiva, tilikausi.alkaa, tilikausi.loppuu))
  }
  annaTilikausiKuukaudenEnsimmaisellePaivalle(tilikaudet: Tilikausi[], valittuKuukausi: LocalMonth): Tilikausi {
    const valittuAsLocalDate: LocalDate = { year: valittuKuukausi.year, month: valittuKuukausi.month, day: 1 }
    return this.annaTilikausiPaivalle(tilikaudet, valittuAsLocalDate)
  }
  annaEdellinenTilikausi(tilikaudet: Tilikausi[], tilikausi: Tilikausi): Tilikausi {
    if (!tilikausi) { return null }
    const endDayOfPrevious = this._dateService.lisaaPaiviaPaikallinen(tilikausi.alkaa, -1)
    return tilikaudet?.find(til => this._dateService.compareLocalDates(til.loppuu, '==', endDayOfPrevious))
  }
  annaEdellinenTilikausiJosAvoin(tilikaudet: Tilikausi[], tilikausi: Tilikausi): Tilikausi {
    if (!tilikausi) { return null }
    const endDayOfPrevious = this._dateService.lisaaPaiviaPaikallinen(tilikausi.alkaa, -1)
    return tilikaudet?.find(til => this._dateService.compareLocalDates(til.loppuu, '==', endDayOfPrevious) && !til.lukittu)
  }
  annaEdellinenTilikausiPaivalle(tilikaudet: Tilikausi[], pvm: LocalDate): Tilikausi {
    if (!pvm || !tilikaudet) { return null }
    let found: Tilikausi = null
    for (const t of tilikaudet) {
      if (this._dateService.compareLocalDates(t.loppuu, '<=', pvm)) {
        if (!found || this._dateService.compareLocalDates(found.loppuu, '<', t.loppuu)) {
          found = t
        }
      }
    }
    return found
  }
  annaKirjauksenSummat(kirjaus: Kirjaus): { debet: number, kredit: number } {
    if (kirjaus?.rivit) {
      let debetYhteensa = 0
      let kreditYhteensa = 0
      for (const rivi of kirjaus.rivit) {
        debetYhteensa += rivi.d ? rivi.d : 0
        kreditYhteensa += rivi.k ? rivi.k : 0
      }
      return { debet: this._currencyService.roundHalfUp(debetYhteensa, 2), kredit: this._currencyService.roundHalfUp(kreditYhteensa, 2) }
    }
    return { debet: 0, kredit: 0 }
  }

  arvoPuuttuu(arvo: number): boolean {
    return arvo === null || arvo === undefined
  }

  arvoOnVirheellinen(arvo: number): boolean {
    return arvo === null || arvo === undefined || isNaN(arvo) || arvo < 0
  }

  onkoAsiakkaanAlvtLukossa(alvIlmoitusjakso: AlvIlmoitusjakso): boolean {
    return !alvIlmoitusjakso || alvIlmoitusjakso === AlvIlmoitusjakso.EI || alvIlmoitusjakso === AlvIlmoitusjakso.TUNTEMATON
  }

  /**
   * NOTE! IF YOU UPDATE THIS, UPDATE KirjanpitoComponent.paivitaNaytettavanKirjauksenValidointi method too!
   * NOTE! THIS IS INTENTIONALLY KEPT FORMATTED AS CLOSE AS THE ONE IN KirjanpitoComponent!
   */
  onkoKirjausOk(kirjaus: Kirjaus, asiakkaanAlvtLukossa: boolean, tilitMap: Map<string, Kirjanpitotili>, salliErottaminen: 'kirjaus-saa-erottaa' | 'kirjaus-ei-saa-erottaa'): boolean {

    // If no kirjaus or no rows, no go
    if (!kirjaus || !kirjaus.rivit || kirjaus.rivit.length < 1) {
      return false
    }

    const kirjausOnJaksotus = kirjaus.jaksotus?.jaksotus === kirjaus.avain
    const kirjausOnJaksotuksenVastakirjaus = kirjaus.jaksotus?.jaksotuksenVastakirjaus === kirjaus.avain

    for (const rivi of kirjaus.rivit) {

      let tilivirhe = false
      let debetvirhe = false
      let kreditvirhe = false
      let alvvirhe = false

      // Tili tarvitaan aina
      if (!rivi.t) {
        tilivirhe = true
      }

      // Kreditistä ja debitistä tarvitaan vain toinen
      if (this.arvoPuuttuu(rivi.d) && this.arvoPuuttuu(rivi.k)) {
        debetvirhe = this.arvoOnVirheellinen(rivi.d)
        kreditvirhe = this.arvoOnVirheellinen(rivi.k)
      } else if (this.arvoPuuttuu(rivi.d)) {
        debetvirhe = false
        kreditvirhe = this.arvoOnVirheellinen(rivi.k)
      } else if (this.arvoPuuttuu(rivi.k)) {
        debetvirhe = this.arvoOnVirheellinen(rivi.d)
        kreditvirhe = false
      } else {
        debetvirhe = true
        kreditvirhe = true
      }



      // ALV kenttä tarvitaan, mikäli rivin tilin alv-tyyppi on myynti tai osto.
      const tili = tilitMap.get(rivi.t) || null
      if (
        !kirjausOnJaksotus &&
        !kirjausOnJaksotuksenVastakirjaus &&
        rivi.t &&
        !asiakkaanAlvtLukossa &&
        (
          tili.alvTyyppi === KirjanpitotilinAlvTyyppi.MYYNTI ||
          tili.alvTyyppi === KirjanpitotilinAlvTyyppi.OSTO ||
          tili.alvTyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI ||
          tili.alvTyyppi === KirjanpitotilinAlvTyyppi.ETAMYYNTI_EI_REKISTEROITYNYT
        )
      ) {
        alvvirhe = !rivi.a
      } else {
        alvvirhe = false
      }

      // Päivitä palautettava kokonaisvirheindikaattori
      if (
        tilivirhe ||
        debetvirhe ||
        kreditvirhe ||
        alvvirhe
      ) {
        return false
      }

    }

    const summat = this.annaKirjauksenSummat(kirjaus)

    if (summat.debet < 0.00) {
      return false
    }

    if (summat.kredit < 0.00) {
      return false
    }

    if (salliErottaminen === 'kirjaus-saa-erottaa') {
      return true
    }

    if (summat.debet !== summat.kredit) {
      return false
    }

    return true

  }

  private _annaAlvnErapaivaInternal(clientType: 'holvi' | 'regular', kuukausi: LocalMonth, ilmoitusJakso: AlvIlmoitusjaksoAikajaksolla, seuraavaIlmoitusJakso: AlvIlmoitusjaksoAikajaksolla): LocalDate {

    let pyhapaivaCorrectedLocalDay: LocalDate = null

    if (ilmoitusJakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {
      const lastDayOfNextFeb = this._dateService.kuukaudenViimeinen(new Date(kuukausi.year + 1, 1, 1))
      const lastDayMinusTwo = this._dateService.lisaaPaivia(lastDayOfNextFeb, -2)
      const pyhapaivaCorrectedDay = this._pyhapaivatService.annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(lastDayMinusTwo)
      pyhapaivaCorrectedLocalDay = this._dateService.dateToLocalDate(pyhapaivaCorrectedDay)
    } else {
      const kuukausiDate: Date = new Date(kuukausi.year, kuukausi.month - 1, 12)
      const dueDate = this._dateService.lisaaKuukausia(kuukausiDate, 2)
      const pyhapaivaCorrectedDay = this._pyhapaivatService.annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(dueDate)
      pyhapaivaCorrectedLocalDay = this._dateService.dateToLocalDate(pyhapaivaCorrectedDay)
    }

    const kk3AndNotTheEndMonth = ilmoitusJakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK3 && (this._dateService.compareLocalMonths(this._annaKk3AlkamisJaPaattymisPaiva(kuukausi, ilmoitusJakso)?.end, '!=', kuukausi))

    // This takes care of case if the next jakso shortens this one.
    if (ilmoitusJakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK12 || kk3AndNotTheEndMonth) {
      if (seuraavaIlmoitusJakso && this._dateService.compareLocalDates(seuraavaIlmoitusJakso.voimassaAlkaen, '<', pyhapaivaCorrectedLocalDay)) {
        const twelfth: LocalDate = { year: seuraavaIlmoitusJakso.voimassaAlkaen.year, month: seuraavaIlmoitusJakso.voimassaAlkaen.month, day: 12 }
        const twelfthNextMonth = this._dateService.lisaaKuukausiaPaikallinen(twelfth, 1)
        const pyhapaivaCorrectedDay2 = this._pyhapaivatService.annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(this._dateService.localDateToDate(twelfthNextMonth))
        pyhapaivaCorrectedLocalDay = this._dateService.dateToLocalDate(pyhapaivaCorrectedDay2)
      }
    }

    // Note: different days for Holvi & regular clients
    if (clientType === 'holvi') {
      return this._dateService.lisaaPaiviaPaikallinen(pyhapaivaCorrectedLocalDay, -1)
    }

    return pyhapaivaCorrectedLocalDay

  }

  onkoAlvnErapaivaMenneisyydessa(clientType: 'holvi' | 'regular', kuukausi: LocalMonth, ilmoitusJakso: AlvIlmoitusjaksoAikajaksolla, seuraavaIlmoitusJakso: AlvIlmoitusjaksoAikajaksolla, nyt?: LocalDate): boolean {
    const erapaiva = this._annaAlvnErapaivaInternal(clientType, kuukausi, ilmoitusJakso, seuraavaIlmoitusJakso)
    const usedNow = nyt ?? this._dateService.currentLocalDate()
    return this._dateService.compareLocalDates(erapaiva, '<', usedNow)
  }

  annaAlvnErapaiva(clientType: 'holvi' | 'regular', kuukausi: LocalMonth, ilmoitusJakso: AlvIlmoitusjaksoAikajaksolla, seuraavaIlmoitusJakso: AlvIlmoitusjaksoAikajaksolla, nyt?: LocalDate): LocalDate {
    const erapaiva = this._annaAlvnErapaivaInternal(clientType, kuukausi, ilmoitusJakso, seuraavaIlmoitusJakso)
    const usedNow = nyt ?? this._dateService.currentLocalDate()
    if (this._dateService.compareLocalDates(erapaiva, '<', usedNow)) {
      return usedNow
    }
    return erapaiva
  }

  annaAlvnAlkuperainenErapaiva(clientType: 'holvi' | 'regular', kuukausi: LocalMonth, ilmoitusJakso: AlvIlmoitusjaksoAikajaksolla, seuraavaIlmoitusJakso: AlvIlmoitusjaksoAikajaksolla): LocalDate {
    return this._annaAlvnErapaivaInternal(clientType, kuukausi, ilmoitusJakso, seuraavaIlmoitusJakso)
  }

  getClientTypeAsString(asiakas: Pick<Asiakas, 'kasittelija'>): 'holvi' | 'regular' {
    if (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
      return 'holvi'
    }
    return 'regular'
  }

  muutaLaskuLiitetyiksiSivuiksi(lasku: Lasku, dataToUpdate: Partial<KirjaukseenLiitettyjenTiedostojenSivut>) {

    if (!dataToUpdate.t) {
      dataToUpdate.t = {}
    }

    // Luo kuva laskusta
    const kuva: KirjaukseenLiitetynTiedostonSivu = {
      o: new Date().getTime(),
      t: KirjaukseenLiitetynTiedostonSivunTyyppi.LASKU,
      l: lasku
    }
    dataToUpdate.t[lasku.avain] = kuva

  }

  private annaKuitinKuvatJarjestyksessaAlkuperaiselle(tosite: FirestoreTosite, alkuperainen: FirestoreTositteenAlkuperainenTiedosto): string[] {
    const kuvat = tosite.kuvat ?? {}
    return Object.keys(kuvat).filter(key => kuvat[key]?.alkuperaisenAvain === alkuperainen?.avain).sort((aKey, bKey) => {
      const a = kuvat[aKey]
      const b = kuvat[bKey]
      if (a.jarjestys === b.jarjestys) {
        return aKey.localeCompare(bKey)
      }
      return a.jarjestys - b.jarjestys
    })
  }

  muutaKuittiLiitetyiksiSivuiksi(kuitti: FirestoreTosite, dataToUpdate: Partial<KirjaukseenLiitettyjenTiedostojenSivut>) {
    // Luo liitettyjenTiedostojenSivut kuitista

    if (!dataToUpdate.t) {
      dataToUpdate.t = {}
    }

    const currentTime = new Date().getTime()
    let o = 1
    const alkprst = Object.values(kuitti.alkuperaiset || [])
    const addedAlkuperaiset = new Set<string>()
    for (const alkuperainen of alkprst) {
      if (alkuperainen.kasitelty && alkuperainen.fileEnding !== 'pdf') {
        continue
      }
      if (alkuperainen.fileEnding === 'pdf' && alkuperainen.kasitelty) {
        dataToUpdate.t[alkuperainen.avain] = {
          o: currentTime + o,
          t: KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_PDF_RAJAYTETTY_JPEG_SIVUIKSI,
          a: kuitti.avain,
          k: alkuperainen.kuvakansio,
          f: alkuperainen.fileEnding,
          y: kuitti.kuvakansio
        }
        const avaimet = this.annaKuitinKuvatJarjestyksessaAlkuperaiselle(kuitti, alkuperainen)
        if (avaimet && avaimet.length > 0) {
          dataToUpdate.t[alkuperainen.avain].c = avaimet
        }
      } else {
        dataToUpdate.t[alkuperainen.avain] = {
          o: currentTime + o,
          t: alkuperainen.fileEnding === 'pdf' ? KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_PDF : KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_MUU,
          a: kuitti.avain,
          k: alkuperainen.kuvakansio,
          f: alkuperainen.fileEnding
        }
      }

      addedAlkuperaiset.add(alkuperainen.avain)
      o++
    }
    const kvt = Object.values(kuitti.kuvat || []).sort((a, b) => {
      if (a.alkuperaisenAvain === b.alkuperaisenAvain) {
        return a.jarjestys - b.jarjestys
      }
      if (a.alkuperaisenAvain && b.alkuperaisenAvain) {
        return a.alkuperaisenAvain.localeCompare(b.alkuperaisenAvain)
      }
      if (a.alkuperaisenAvain) {
        return 1
      }
      if (b.alkuperaisenAvain) {
        return -1
      }
      return 0
    })
    for (const kuva of kvt) {
      if (kuva.poistettu) {
        continue
      }
      if (kuva.alkuperaisenAvain && addedAlkuperaiset.has(kuva.alkuperaisenAvain)) {
        continue
      }
      dataToUpdate.t[kuva.avain] = {
        o: currentTime + o,
        t: KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_JPEG,
        a: kuitti.avain,
        k: kuitti.kuvakansio
      }
      o++
    }

    // Jos tästä kuitista ei ollut yhtään sivua tuloksessa, lisätään "ei sivuja" sivu.
    const eiYhtaan = Object.values(dataToUpdate.t).filter(sivu => sivu.a === kuitti.avain).length < 1
    if (eiYhtaan) {
      const uusiSivu: KirjaukseenLiitetynTiedostonSivu = {
        t: KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_NO_PAGES,
        o: new Date().getTime(),
        a: kuitti.avain,
        k: kuitti.kuvakansio
      }
      dataToUpdate.t[kuitti.avain] = uusiSivu
    }

  }

  laskeAlvIlmoituksenSummatKirjauksista(kirjaukset: Raportointikirjaus[]): RaporttikirjauksistaLasketutAlvIlmoituksenSummat {

    let alv24 = 0
    let alv255 = 0
    let alv14 = 0
    let alv10 = 0
    let taveu = 0
    let palveu = 0
    let taveieu = 0
    let rak = 0
    let vahennettava = 0
    let nolla = 0
    let tavmyynniteu = 0
    let palvmyynniteu = 0
    let tavostoeu = 0
    let palvostoeu = 0
    let tavulkeu = 0
    let rakmetmyynti = 0
    let rakmetosto = 0

    let tili2939Kokonaan = 0
    let tili1763Kokonaan = 0

    if (kirjaukset) {
      for (const kirjaus of kirjaukset) {

        if (kirjaus.r) {
          for (const rivi of kirjaus.r) {

            if (!rivi.a) {
              continue
            }

            const t = rivi.a
            if (rivi.t === '2939') {

              // Maksettava ALV
              // Näistä lasketaan veron osuus

              // console.log(rivi.alv)

              if (t === MyyntiAlvt.MYYNTI_255.tunniste) { // Myynnit
                alv255 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_24.tunniste) { // Myynnit
                alv24 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_14.tunniste) {
                alv14 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_10.tunniste) {
                alv10 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_255.tunniste || t === OstoAlvt.OSTO_24.tunniste || t === OstoAlvt.OSTO_14.tunniste || t === OstoAlvt.OSTO_10.tunniste || t === OstoAlvt.OSTO_100.tunniste) { // Ostot
                // vahennettava += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_TAVARA_255.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_24.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_14.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_10.tunniste) { // Ostot
                taveu += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_PALVELU_255.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_24.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_14.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_10.tunniste) {
                palveu += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_255.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_24.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_14.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_10.tunniste) {
                taveieu += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_RAKENNUSPALVELU_255.tunniste || t === OstoAlvt.OSTO_RAKENNUSPALVELU_24.tunniste) {
                rak += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_PALVELU_255.tunniste) {
                alv255 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_PALVELU_24.tunniste) {
                alv24 += this.annaLukema(rivi)
                tili2939Kokonaan += this.annaLukema(rivi)
                // vahennettava += this.annaLukema(rivi)
              }
            } else if (rivi.t === '1763') {

              // Vähennettävä ALV
              // Näistä lasketaan veron osuus

              // if (t === MyyntiAlvt.MYYNTI_24.tunniste) { // Myynnit
              //   alv24 += this.annaLukema(rivi)
              // } else if (t === MyyntiAlvt.MYYNTI_14.tunniste) {
              //   alv14 += this.annaLukema(rivi)
              // } else if (t === MyyntiAlvt.MYYNTI_10.tunniste) {
              //   alv10 += this.annaLukema(rivi)
              // } else
              if (t === OstoAlvt.OSTO_255.tunniste || t === OstoAlvt.OSTO_24.tunniste || t === OstoAlvt.OSTO_14.tunniste || t === OstoAlvt.OSTO_10.tunniste || t === OstoAlvt.OSTO_100.tunniste) { // Ostot
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_TAVARA_255.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_24.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_14.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_10.tunniste) { // Ostot
                // taveu += this.annaLukema(rivi)
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_PALVELU_255.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_24.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_14.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_10.tunniste) {
                // palveu += this.annaLukema(rivi)
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_255.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_24.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_14.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_10.tunniste) {
                // taveieu += this.annaLukema(rivi)
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_RAKENNUSPALVELU_255.tunniste || t === OstoAlvt.OSTO_RAKENNUSPALVELU_24.tunniste) {
                // rak += this.annaLukema(rivi)
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_PALVELU_255.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_PALVELU_24.tunniste) {
                // alv24 += this.annaLukema(rivi)
                vahennettava += this.annaLukema(rivi)
                tili1763Kokonaan += this.annaLukema(rivi)
              }

            } else if (rivi.t === '2946') {
              // nolla += this.annaLukemaAlvstaPaateltyna(rivi)
            } else {

              // Näistä lasketaan veroton osuus

              if (t === MyyntiAlvt.MYYNTI_EU_PALVELU_255.tunniste || t === MyyntiAlvt.MYYNTI_EU_PALVELU_24.tunniste || t === MyyntiAlvt.MYYNTI_EU_PALVELU_14.tunniste || t === MyyntiAlvt.MYYNTI_EU_PALVELU_10.tunniste) {
                palvmyynniteu += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_EU_TAVARA_255.tunniste || t === MyyntiAlvt.MYYNTI_EU_TAVARA_24.tunniste || t === MyyntiAlvt.MYYNTI_EU_TAVARA_14.tunniste || t === MyyntiAlvt.MYYNTI_EU_TAVARA_10.tunniste) {
                tavmyynniteu += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_0_VEROKANNAN_ALAINEN_LV.tunniste) {
                nolla += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === OstoAlvt.OSTO_EU_TAVARA_255.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_24.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_14.tunniste || t === OstoAlvt.OSTO_EU_TAVARA_10.tunniste) { // Ostot
                tavostoeu += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === OstoAlvt.OSTO_EU_PALVELU_255.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_24.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_14.tunniste || t === OstoAlvt.OSTO_EU_PALVELU_10.tunniste) {
                palvostoeu += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_255.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_24.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_14.tunniste || t === OstoAlvt.OSTO_EU_ULKOP_TUONTI_10.tunniste) {
                tavulkeu += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === OstoAlvt.OSTO_RAKENNUSPALVELU_255.tunniste || t === OstoAlvt.OSTO_RAKENNUSPALVELU_24.tunniste) {
                rakmetosto += this.annaLukemaAlvstaPaateltyna(rivi)
              } else if (t === MyyntiAlvt.MYYNTI_RAKENNUSPALVELU_0.tunniste) { // t === MyyntiAlvt.MYYNTI_RAKENNUSPALVELU_24.tunniste ||
                rakmetmyynti += this.annaLukemaAlvstaPaateltyna(rivi)
              } else {
                const maaritys = this._alvService.puraEtamyyntiTunnisteTaiNull(t)
                if (maaritys) {
                  nolla += this._annaMyyntilukema(rivi)
                }
              }

            }

          }
        }
      }
    }

    return {
      alv255: this._currencyService.roundHalfUp(alv255, 2),
      alv24: this._currencyService.roundHalfUp(alv24, 2),
      alv14: this._currencyService.roundHalfUp(alv14, 2),
      alv10: this._currencyService.roundHalfUp(alv10, 2),
      taveu: this._currencyService.roundHalfUp(taveu, 2),
      palveu: this._currencyService.roundHalfUp(palveu, 2),
      taveieu: this._currencyService.roundHalfUp(taveieu, 2),
      rak: this._currencyService.roundHalfUp(rak, 2),
      vahennettava: this._currencyService.roundHalfUp(vahennettava, 2),
      nolla: this._currencyService.roundHalfUp(nolla, 2),
      tavmyynniteu: this._currencyService.roundHalfUp(tavmyynniteu, 2),
      palvmyynniteu: this._currencyService.roundHalfUp(palvmyynniteu, 2),
      tavostoeu: this._currencyService.roundHalfUp(tavostoeu, 2),
      palvostoeu: this._currencyService.roundHalfUp(palvostoeu, 2),
      tavulkeu: this._currencyService.roundHalfUp(tavulkeu, 2),
      rakmetmyynti: this._currencyService.roundHalfUp(rakmetmyynti, 2),
      rakmetosto: this._currencyService.roundHalfUp(rakmetosto, 2),
      tili2939Kokonaan: this._currencyService.roundHalfUp(tili2939Kokonaan, 2),
      tili1763Kokonaan: this._currencyService.roundHalfUp(tili1763Kokonaan, 2)
    }

  }

  /**
   * MYYNNIT: Kredit == +, Debet == -
   * */
  private _annaMyyntilukema(rivi: Raportointikirjausrivi): number {
    if (rivi.d !== null && rivi.d !== undefined && !isNaN(rivi.d)) {
      return 0 - rivi.d
    } else if (rivi.k !== null && rivi.k !== undefined && !isNaN(rivi.k)) {
      return rivi.k
    }
    throw new Error('Virheellinen rivi 1: ' + JSON.stringify(rivi))
  }

  /**
   * OSTO: Kredit == -, Debet == +
   */
  private _annaOstolukema(rivi: Raportointikirjausrivi): number {
    if (rivi.d !== null && rivi.d !== undefined && !isNaN(rivi.d)) {
      return rivi.d
    } else if (rivi.k !== null && rivi.k !== undefined && !isNaN(rivi.k)) {
      return 0 - rivi.k
    }
    throw new Error('Virheellinen rivi 2; ' + JSON.stringify(rivi))
  }

  // MYYNNIT: Kredit == +, Debet == -
  // OSTO: Kredit == -, Debet == +
  private annaLukema(rivi: Raportointikirjausrivi): number {
    if (rivi.t === '2939') { // MYYNTI
      return this._annaMyyntilukema(rivi)
    } else if (rivi.t === '1763') { // OSTO
      return this._annaOstolukema(rivi)
    }
    throw new Error('Virheellinen rivi 3, ' + JSON.stringify(rivi))
  }

  // MYYNNIT: Kredit == +, Debet == -
  // OSTO: Kredit == -, Debet == +
  private annaLukemaAlvstaPaateltyna(rivi: Raportointikirjausrivi): number {
    if (MyyntiAlvt.onkoMyyntiAlv(rivi.a)) { // MYYNTI
      return this._annaMyyntilukema(rivi)
    } else if (OstoAlvt.onkoOstoAlv(rivi.a)) { // OSTO
      return this._annaOstolukema(rivi)
    }
    throw new Error('Virheellinen rivi 4: ' + JSON.stringify(rivi))
  }

  onkoAlvIlmoituksissaTaiArhssaEroja(alvIlmoitus: AlvIlmoitus, edellinenAlvIlmoitus: AlvIlmoitus): boolean {
    if (alvIlmoitus && edellinenAlvIlmoitus) {
      // ARH
      if (alvIlmoitus.arhSyotetty && !edellinenAlvIlmoitus.arhSyotetty) {
        // console.log('arh toisessa, mutta ei toisessa 1')
        return true
      }
      if (!alvIlmoitus.arhSyotetty && edellinenAlvIlmoitus.arhSyotetty) {
        // console.log('arh toisessa, mutta ei toisessa 2')
        return true
      }
      if (alvIlmoitus.arhSyotetty && edellinenAlvIlmoitus.arhSyotetty) {
        if (this._currencyService.roundHalfUp(alvIlmoitus.arhSyotetty.huojennus, 2) !== this._currencyService.roundHalfUp(edellinenAlvIlmoitus.arhSyotetty.huojennus, 2)) {
          // console.log('arh summa')
          return true
        }
      }
    }

    return this.onkoAlvIlmoituksissaEroja(alvIlmoitus, edellinenAlvIlmoitus)
  }

  onkoAlvIlmoituksissaEroja(ilmoitus1: AlvIlmoitus, ilmoitus2: AlvIlmoitus): boolean {

    if (!ilmoitus1 && !ilmoitus2) {
      return false
    }

    if (!ilmoitus1 && ilmoitus2) {
      return true
    }

    if (ilmoitus1 && !ilmoitus2) {
      return true
    }

    if (this._currencyService.roundHalfUp(ilmoitus1.alv255, 2) !== this._currencyService.roundHalfUp(ilmoitus2.alv255, 2)) {
      // console.log('alv255', this._currencyService.roundHalfUp(ilmoitus1.alv255, 2), this._currencyService.roundHalfUp(ilmoitus2.alv255, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.alv24, 2) !== this._currencyService.roundHalfUp(ilmoitus2.alv24, 2)) {
      // console.log('alv24', this._currencyService.roundHalfUp(ilmoitus1.alv24, 2), this._currencyService.roundHalfUp(ilmoitus2.alv24, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.alv14, 2) !== this._currencyService.roundHalfUp(ilmoitus2.alv14, 2)) {
      // console.log('alv14', this._currencyService.roundHalfUp(ilmoitus1.alv14, 2), this._currencyService.roundHalfUp(ilmoitus2.alv14, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.alv10, 2) !== this._currencyService.roundHalfUp(ilmoitus2.alv10, 2)) {
      // console.log('alv10', this._currencyService.roundHalfUp(ilmoitus1.alv10, 2), this._currencyService.roundHalfUp(ilmoitus2.alv10, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.taveu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.taveu, 2)) {
      // console.log('taveu', this._currencyService.roundHalfUp(ilmoitus1.taveu, 2), this._currencyService.roundHalfUp(ilmoitus2.taveu, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.palveu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.palveu, 2)) {
      // console.log('palveu', this._currencyService.roundHalfUp(ilmoitus1.palveu, 2), this._currencyService.roundHalfUp(ilmoitus2.palveu, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.taveieu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.taveieu, 2)) {
      // console.log('taveieu', this._currencyService.roundHalfUp(ilmoitus1.taveieu, 2), this._currencyService.roundHalfUp(ilmoitus2.taveieu, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.rak, 2) !== this._currencyService.roundHalfUp(ilmoitus2.rak, 2)) {
      // console.log('rak', this._currencyService.roundHalfUp(ilmoitus1.rak, 2), this._currencyService.roundHalfUp(ilmoitus2.rak, 2))
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.vahennettava, 2) !== this._currencyService.roundHalfUp(ilmoitus2.vahennettava, 2)) {
      // console.log('vahennettava')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.tavmyynniteu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.tavmyynniteu, 2)) {
      // console.log('tavmyynniteu')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.palvmyynniteu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.palvmyynniteu, 2)) {
      // console.log('palvmyynniteu')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.tavostoeu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.tavostoeu, 2)) {
      // console.log('tavostoeu')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.palvostoeu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.palvostoeu, 2)) {
      // console.log('palvostoeu')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.tavulkeu, 2) !== this._currencyService.roundHalfUp(ilmoitus2.tavulkeu, 2)) {
      // console.log('tavulkeu')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.rakmetmyynti, 2) !== this._currencyService.roundHalfUp(ilmoitus2.rakmetmyynti, 2)) {
      // console.log('rakmetmyynti')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.rakmetosto, 2) !== this._currencyService.roundHalfUp(ilmoitus2.rakmetosto, 2)) {
      // console.log('rakmetosto')
      return true
    }
    if (this._currencyService.roundHalfUp(ilmoitus1.nolla, 2) !== this._currencyService.roundHalfUp(ilmoitus2.nolla, 2)) {
      // console.log('nolla')
      return true
    }

    return false

  }

  annaAlvIlmoituksenJaksotiedot(valittuKuukausi: LocalMonth, asiakas: Asiakas): AlvIlmoituksenJaksotiedot {

    const asDate = this._dateService.localDateToDate({ year: valittuKuukausi.year, month: valittuKuukausi.month, day: 15 })
    const jakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, asDate)

    if (!jakso) {
      console.error('Asiakkaalla ' + asiakas.avain + ' ei ole voimassa olevaa alv-ilmoitusjaksoa päivämäärällä ' + JSON.stringify(valittuKuukausi) + '.')
      // throw new Error()
      return null
    }

    const seuraavaJakso = this._asiakasJaettuService.annaSeuraavaAlvIlmoitusjakso(asiakas, jakso)
    const edellinenJakso = this._asiakasJaettuService.annaEdellinenAlvIlmoitusjakso(asiakas, jakso)

    const year = valittuKuukausi.year
    let ret: { jakso: AlvIlmoitusjakso, aikavali: PaivamaaraAikavali } = null

    if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK1) {
      ret = {
        aikavali: this._annaKk1AlkamisJaPaattymisPaiva(valittuKuukausi, jakso),
        jakso: AlvIlmoitusjakso.KK1
      }
    } else if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK3) {

      const alkamisJaPaattymisPaiva = this._annaKk3AlkamisJaPaattymisPaiva(valittuKuukausi, jakso)

      // chop off date, if the next jakso starts in the months that was found
      if (seuraavaJakso) {
        if (
          seuraavaJakso.voimassaAlkaen.year === year &&
          seuraavaJakso.voimassaAlkaen.month >= alkamisJaPaattymisPaiva.start.month &&
          seuraavaJakso.voimassaAlkaen.month <= alkamisJaPaattymisPaiva.end.month
        ) {
          alkamisJaPaattymisPaiva.end = this._dateService.lisaaKuukausiaPaikallinen(seuraavaJakso.voimassaAlkaen, -1)
          alkamisJaPaattymisPaiva.end = this._dateService.kuukaudenViimeinenPaikallinen(alkamisJaPaattymisPaiva.end)
        }
      }

      ret = {
        aikavali: alkamisJaPaattymisPaiva,
        jakso: AlvIlmoitusjakso.KK3
      }

    } else if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {

      const alkamisJaPaattymisPaiva = this._annaKk12AlkamisJaPaattymisPaiva(valittuKuukausi, jakso)

      // chop off date, if the next jakso starts in the months that was found
      if (seuraavaJakso) {
        if (
          seuraavaJakso.voimassaAlkaen.year === year &&
          seuraavaJakso.voimassaAlkaen.month >= 1 &&
          seuraavaJakso.voimassaAlkaen.month <= 12
        ) {
          alkamisJaPaattymisPaiva.end = this._dateService.lisaaKuukausiaPaikallinen(seuraavaJakso.voimassaAlkaen, -1)
          alkamisJaPaattymisPaiva.end = this._dateService.kuukaudenViimeinenPaikallinen(alkamisJaPaattymisPaiva.end)
        }
      }

      ret = {
        aikavali: alkamisJaPaattymisPaiva,
        jakso: AlvIlmoitusjakso.KK12
      }

    }

    if (
      ret &&
      edellinenJakso &&
      edellinenJakso.alvIlmoitusjakso !== AlvIlmoitusjakso.EI &&
      edellinenJakso.alvIlmoitusjakso !== AlvIlmoitusjakso.TUNTEMATON
    ) {
      // Jos edellinen jakso löytyy ja tulee laskennallisen ALV-kauden päälle, lyhennetään kautta.
      const edellinenPaattyy = this._dateService.lisaaPaiviaPaikallinen(jakso.voimassaAlkaen, -1)
      const edellisenAlkamisJaPaattymispaiva = this.annaAlvIlmoituksenJaksotiedot(edellinenPaattyy, asiakas)
      if (edellisenAlkamisJaPaattymispaiva?.aikavali && this._dateService.compareLocalDates(edellisenAlkamisJaPaattymispaiva.aikavali.end, '>', ret.aikavali.start)) {
        ret.aikavali.start = this._dateService.lisaaPaiviaPaikallinen(edellisenAlkamisJaPaattymispaiva.aikavali.end, 1)
      }
    }

    return ret

  }

  private _annaKk1AlkamisJaPaattymisPaiva(kuukausi: LocalMonth, jakso: AlvIlmoitusjaksoAikajaksolla) {
    if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK1) {
      const firstAsDate = this._dateService.localDateToDate({ year: kuukausi.year, month: kuukausi.month, day: 1 })
      const lastOfMonth = this._dateService.kuukaudenViimeinen(firstAsDate)
      return {
        start: { year: kuukausi.year, month: kuukausi.month, day: 1 },
        end: this._dateService.dateToLocalDate(lastOfMonth)
      }
    }
    throw new Error('ALV-jakso ei ole KK1')
  }

  private _annaKk3AlkamisJaPaattymisPaiva(kuukausi: LocalMonth, jakso: AlvIlmoitusjaksoAikajaksolla) {
    if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK3) {
      let startDay: LocalDate = null
      let endDay: LocalDate = null
      if (kuukausi.month === 1 || kuukausi.month === 2 || kuukausi.month === 3) {
        startDay = { year: kuukausi.year, month: 1, day: 1 }
        endDay = { year: kuukausi.year, month: 3, day: 31 }
      } else if (kuukausi.month === 4 || kuukausi.month === 5 || kuukausi.month === 6) {
        startDay = { year: kuukausi.year, month: 4, day: 1 }
        endDay = { year: kuukausi.year, month: 6, day: 30 }
      } else if (kuukausi.month === 7 || kuukausi.month === 8 || kuukausi.month === 9) {
        startDay = { year: kuukausi.year, month: 7, day: 1 }
        endDay = { year: kuukausi.year, month: 9, day: 30 }
      } else if (kuukausi.month === 10 || kuukausi.month === 11 || kuukausi.month === 12) {
        startDay = { year: kuukausi.year, month: 10, day: 1 }
        endDay = { year: kuukausi.year, month: 12, day: 31 }
      } else {
        throw new Error('Unknown month / date ' + JSON.stringify(kuukausi))
      }
      return {
        start: startDay,
        end: endDay
      }
    }
    throw new Error('ALV-jakso ei ole KK3')

  }

  private _annaKk12AlkamisJaPaattymisPaiva(kuukausi: LocalMonth, jakso: AlvIlmoitusjaksoAikajaksolla) {
    if (jakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {

      const startDay: LocalDate = { year: kuukausi.year, month: 1, day: 1 }
      const endDay: LocalDate = { year: kuukausi.year, month: 12, day: 31 }

      return {
        start: startDay,
        end: endDay
      }
    }
    throw new Error('ALV-jakso ei ole KK12')
  }

  annaAlvilmoituksenTunniste(tunnistetiedot: AlvIlmoituksenTunnistetiedot): string {
    return tunnistetiedot.alvIlmoitusjakso + '_' + tunnistetiedot.vuosi + '_' + tunnistetiedot.jarjestys
  }

  annaUusiAlvilmoitus(): AlvIlmoitus {
    return {

      avain: null,
      tunniste: null,

      alv255: 0,
      alv24: 0,
      alv14: 0,
      alv10: 0,

      taveu: 0,
      palveu: 0,
      taveieu: 0,
      rak: 0,

      vahennettava: 0,

      nolla: 0,
      tavmyynniteu: 0,
      palvmyynniteu: 0,
      tavostoeu: 0,
      palvostoeu: 0,
      tavulkeu: 0,
      rakmetmyynti: 0,
      rakmetosto: 0,

      edellinenMaksettavaTaiPalautettavaVero: 0,

      startDate: null,
      endDate: null,
      lahetetty: null,
      tallennettu: null,
      tallentaja: null,
      luotu: null,
      luoja: null,
      lukittu: false,

      tunnistetiedot: {
        alvIlmoitusjakso: null,
        jarjestys: null,
        vuosi: null
      },

      jarjestysnumero: null,

      automaattikirjaus1763Summa: 0,
      automaattikirjaus2939Summa: 0

    }
  }

  copyValuesFromSumsToAlvIlmoitus(alvIlmoitus: AlvIlmoitus, summat: RaporttikirjauksistaLasketutAlvIlmoituksenSummat) {
    alvIlmoitus.alv255 = summat.alv255
    alvIlmoitus.alv24 = summat.alv24
    alvIlmoitus.alv14 = summat.alv14
    alvIlmoitus.alv10 = summat.alv10
    alvIlmoitus.taveu = summat.taveu
    alvIlmoitus.palveu = summat.palveu
    alvIlmoitus.taveieu = summat.taveieu
    alvIlmoitus.rak = summat.rak
    alvIlmoitus.vahennettava = summat.vahennettava
    alvIlmoitus.nolla = summat.nolla
    alvIlmoitus.tavmyynniteu = summat.tavmyynniteu
    alvIlmoitus.palvmyynniteu = summat.palvmyynniteu
    alvIlmoitus.tavostoeu = summat.tavostoeu
    alvIlmoitus.palvostoeu = summat.palvostoeu
    alvIlmoitus.tavulkeu = summat.tavulkeu
    alvIlmoitus.rakmetmyynti = summat.rakmetmyynti
    alvIlmoitus.rakmetosto = summat.rakmetosto

    alvIlmoitus.automaattikirjaus1763Summa = summat.tili1763Kokonaan
    alvIlmoitus.automaattikirjaus2939Summa = summat.tili2939Kokonaan
  }

  /**
  * @param valittuAlku Date selected at the web reports date picker
  *
  * @param tilikaudenAlku The start of the current tilikausi (i.e. that the selected date fits between)
  */
  filterAlkusaldoKirjaukset(kirjaukset: Raportointikirjaus[], flatattuHierarkia: FlatattuHierarkia[], valittuAlku: LocalDate, tilikaudenAlku: LocalDate) {
    const valittuAlkuAsNumber = this._dateService.localDateToNumber(valittuAlku)
    const tilikaudenAlkuAsNumber = tilikaudenAlku ? this._dateService.localDateToNumber(tilikaudenAlku) : 0
    const output: Raportointikirjausrivi[] = []
    for (const kirjaus of kirjaukset) {

      if (kirjaus.r) {
        // const kirjausPvmMillis = kirjaus.p.toMillis()

        for (const rivi of kirjaus.r) {

          // 3000-9999 alkusaldot tilikauden alussa ovat aina 0.
          if (rivi.t && this.onkoTuloslaskelmaTili(rivi.t, flatattuHierarkia)) {
            if (
              kirjaus.p < valittuAlkuAsNumber &&
              kirjaus.p >= tilikaudenAlkuAsNumber
            ) {
              output.push(rivi)
            }
          } else if (rivi.t) {
            if (kirjaus.p < valittuAlkuAsNumber) {
              output.push(rivi)
            }
          }
        }
      }
    }
    return output
  }
  onkoTilikaudenViimeinenKuukausi(valittuKuukausi: LocalMonth, tilikausi: Tilikausi) {
    if (!valittuKuukausi || !tilikausi) {
      console.error('Ei kuukautta tai tilikautta')
      return false
    }
    const tilikaudenLoppu = tilikausi.loppuu
    return valittuKuukausi.year === tilikaudenLoppu.year &&
      valittuKuukausi.month === tilikaudenLoppu.month
  }

  private onkoTuloslaskelmaTili(tilinumero: string, hierarkiaTilit: FlatattuHierarkia[]) {
    const tarkistettavaTili = hierarkiaTilit.find(tili => tili?.kirjanpitotili?.numero === tilinumero)
    if (tarkistettavaTili?.kirjanpitotili?.vanhempi) {
      return this.onkoTuloslaskelmaTili(tarkistettavaTili.kirjanpitotili.vanhempi, hierarkiaTilit)
    }
    return tarkistettavaTili?.kirjanpitotili?.numero === '3'
  }

  public flattaaHierarkia(hierarkiaArr: HierarkiaKirjanpitotili[]): FlatattuHierarkia[] {
    const flattened: FlatattuHierarkia[] = []
    for (const juuri of hierarkiaArr) {
      this.flattaa(juuri, 1, flattened)
    }
    return flattened
  }
  private flattaa(hierarkia: HierarkiaKirjanpitotili, level: number, kaikki: FlatattuHierarkia[]) {
    kaikki.push(this.makeFlat(hierarkia, level))
    if (hierarkia.lapset) {
      for (const lapsi of hierarkia.lapset) {
        this.flattaa(lapsi, level + 1, kaikki)
      }
    }
  }
  private makeFlat(node: HierarkiaKirjanpitotili, level: number): FlatattuHierarkia {
    return {
      level: level,
      kirjanpitotili: node.kirjanpitotili
    }
  }
  annaKuukaudenRaportinAlku(tilikausi: Tilikausi, valittuKuukausi: LocalMonth): LocalDate {
    if (tilikausi.alkaa.month === valittuKuukausi.month &&
      tilikausi.alkaa.year === valittuKuukausi.year) {
      return tilikausi.alkaa
    }
    return { year: valittuKuukausi.year, month: valittuKuukausi.month, day: 1 }
  }

  annaKuukaudenRaportinLoppu(tilikausi: Tilikausi, valittuKuukausi: LocalMonth): LocalDate {
    if (tilikausi.loppuu.month === valittuKuukausi.month &&
      tilikausi.loppuu.year === valittuKuukausi.year) {
      return tilikausi.loppuu
    }
    return this._dateService.kuukaudenViimeinenPaikallinen({ year: valittuKuukausi.year, month: valittuKuukausi.month, day: 1 })
  }

  onkoKirjauksessaSahkoisenTiliotteenTileja(kirjaus: Pick<Kirjaus, 'rivit'>, tilit: Kirjanpitotili[]): boolean {
    const hierarkiat = this._tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
    return this.onkoKirjauksessaSahkoisenTiliotteenTilejaHierarkia(kirjaus, hierarkiat)
  }

  onkoKirjauksessaSahkoisenTiliotteenTilejaHierarkia(kirjaus: Pick<Kirjaus, 'rivit'>, hierarkiat: HierarkiaKirjanpitotili[]): boolean {
    const sahkoisenTiliotteenTilit = this._tilikarttaJaettuService.annaRahaJaPankkisaatavatTilit(hierarkiat)
    const tilinumerot = sahkoisenTiliotteenTilit.map(t => t.numero)

    for (const rivi of (kirjaus?.rivit || [])) {
      if (tilinumerot.includes(rivi.t)) {
        return true
      }
    }
    return false
  }

  onkoKirjauksessa(kirjaus: Pick<Kirjaus, 'rivit'>, tilit: Kirjanpitotili[]) {
    const hierarkiat = this._tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
    const sahkoisenTiliotteenTilit = this._tilikarttaJaettuService.annaRahaJaPankkisaatavatTilit(hierarkiat)
    const tilinumerot = sahkoisenTiliotteenTilit.map(t => t.numero)

    for (const rivi of (kirjaus?.rivit || [])) {
      if (tilinumerot.includes(rivi.t)) {
        return true
      }
    }
    return false
  }

  annaKirjauksenAutomaattinenPoistotili(kirjaus: Pick<Kirjaus, 'rivit'>, tilit: Map<string, Kirjanpitotili>): Kirjanpitotili {
    for (const rivi of (kirjaus?.rivit || [])) {
      const tili = tilit.get(rivi.t)
      if (tili?.poistotiedot) {
        return tili
      }
    }
    return null
  }

  onkoAlkuperainenAutomaattinenPoistokirjaus(kirjaus: Kirjaus) {
    return kirjaus.tunniste === 'automaattipoisto_kuluva_tilikausi' && kirjaus.poistotiedot?.alkuperainen === kirjaus.avain
  }

  annaRaporttiRivinTilinPoistotiedot(
    row: RaporttiPaakirjaAccountRow | RaporttiTaselaskelmaAccountRow | RaporttiTuloslaskelmaAccountRow,
    tilitMap: Map<string, Kirjanpitotili> | null
  ): KirjanpitotilinPoistotiedot {
    return tilitMap?.get(row?.a)?.poistotiedot
  }

  annaPoistotilienMuutostiedot(nykyinenKirjaus: Kirjaus, edellinenKirjaus: Kirjaus | null, asiakkaanKaikkiTilitMap: Map<string, Kirjanpitotili>): PoistotilienMuutostiedot {
    // Tarkastetaan, oliko kirjauksen aiemmassa versiossa automaattisen poiston tili
    const edellisenAutomaattinenPoistotili = edellinenKirjaus && this.annaKirjauksenAutomaattinenPoistotili(edellinenKirjaus, asiakkaanKaikkiTilitMap)
    // Tarkastetaan, onko tallennettavassa kirjauksessa automaattisen poiston tili
    const nykyisenAutomaattinenPoistotili = this.annaKirjauksenAutomaattinenPoistotili(nykyinenKirjaus, asiakkaanKaikkiTilitMap)

    const poistetaanko = edellisenAutomaattinenPoistotili && !nykyisenAutomaattinenPoistotili
    const muutetaanko = edellisenAutomaattinenPoistotili && nykyisenAutomaattinenPoistotili
    const lisataanko = !edellisenAutomaattinenPoistotili && nykyisenAutomaattinenPoistotili

    let tyyppi: PoistotilienMuutostiedot['tyyppi']
    if (poistetaanko) {
      tyyppi = 'poistotili-poistetaan-kirjauksesta';
    } else if (muutetaanko) {
      tyyppi = 'poistotilillista-kirjausta-muokataan';
    } else if (lisataanko) {
      tyyppi = 'kirjaukseen-lisataan-poistotili';
    } else {
      tyyppi = 'ei-poistotileja';
    }

    const poistotilienMuutostiedot: PoistotilienMuutostiedot = {
      tyyppi,
      edellinenPoistotili: edellisenAutomaattinenPoistotili?.numero,
      nykyinenPoistotili: nykyisenAutomaattinenPoistotili?.numero
    }

    return poistotilienMuutostiedot
  }

  /** Tarkastaa vain varsinaiset poistokirjauksen arvot eli jättää esim. avainten tarkastelu pois. */
  onkoAutomaattisenPoistokirjauksenRivitMuuttuneet(
    rivit1: Kirjausrivi[],
    rivit2: Kirjausrivi[]
  ): boolean {
    if (rivit1.length !== rivit2.length) {
      return true
    }
    const onkoRiviMuuttunut = (rivi1: Kirjausrivi, rivi2: Kirjausrivi) => {
      return rivi1?.a !== rivi2?.a || rivi1?.d !== rivi2?.d || rivi1?.k !== rivi2?.k || rivi1?.t !== rivi2?.t
    }
    return rivit1.some((rivi1, index) => onkoRiviMuuttunut(rivi1, rivit2[index]));
  }
}
