import { ErrorHandler, Injectable, OnDestroy, OnInit } from '@angular/core'
import { ErrorResponse } from '../../../_shared-core/model/common'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Subject, combineLatest, filter, take, takeUntil } from 'rxjs'
import { KirjautunutKayttajaService } from '../kirjautunut-kayttaja.service'
import { AsiakasService } from '../asiakas/asiakas.service'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'
import { LadataanService } from '../../../_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from '../firebase-lemonator.service'
import { LahetysTiedot } from '../../../_jaettu-lemonator/model/logging'
import { EnvironmentType } from 'app/app.environment'
import { environment } from 'environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TrackingService implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  private lastNavigationMethod: 'link/url' | 'back/forward'

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _asiakasService: AsiakasService,
    private _timeStampService: TimestampService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _firebaseLemonator: FirebaseLemonator
  ) {

    // combineLatest([
    //   this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
    //   this._asiakasService.nykyinenAsiakasAvainObservable,
    //   this._router.events.pipe(
    //     filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
    // ]).pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(([kirjanpitajanTiedot, asiakas, event]) => {
    //   if (!kirjanpitajanTiedot || !asiakas || !event) {
    //     return
    //   }
    //   if (event instanceof NavigationStart) {
    //     this.determineNavigationMethod(event)
    //   } else if (event instanceof NavigationEnd) {
    //     const routeData = this.route.snapshot.firstChild?.data
    //     if (routeData && routeData.description) {
    //       this.logNavigation(event.urlAfterRedirects, routeData.description, asiakas.avain, kirjanpitajanTiedot.uid)
    //     } else {
    //       this.logNavigation(event.urlAfterRedirects, null, asiakas.avain, kirjanpitajanTiedot.uid)
    //     }
    //   }
    // })

  }

  ngOnInit(): void {
  }

  // logNavigation(url: string, description: string, asiakasAvain: string, kirjanpitajaUid: string) {
  //   const production = this.environmentCheck()
  //   /* if (production) {*/
  //   const lahetysTiedot: LahetysTiedot = {
  //     kirjanpitajaUid: kirjanpitajaUid,
  //     asiakasAvain: asiakasAvain,
  //     navigationEvent: {
  //       eventType: 'navigoi', /* Navigointityyppi */
  //       navigationMethod: this.lastNavigationMethod, /* Navigointitapa */
  //       seen: description /* Mitä tietoja nähtiin */
  //     },
  //     url: url,
  //     timestamp: this._timeStampService.now(),
  //   }
  //   // this.sendLogs(lahetysTiedot)
  //   /* }*/
  // }

  logAction(actionId: string, asiakasAvain: string, kirjanpitajaUid: string) {
    const production = this.environmentCheck()
    if (production) {
      const lahetysTiedot: LahetysTiedot = {
        kirjanpitajaUid: kirjanpitajaUid,
        asiakasAvain: asiakasAvain,
        navigationEvent: {
          eventType: 'klikkasi toimintoa',  /* Navigointityyppi */
          eventName: actionId  /* Mikä toiminto tehtiin */
        },
        url: this._router.url,
        timestamp: this._timeStampService.now(),
      }
      this.sendLogs(lahetysTiedot)
    }
  }

  environmentCheck(): boolean {
    return environment.environment === EnvironmentType.PRODUCTION
  }

  private determineNavigationMethod(event: NavigationStart) {
    if (event.navigationTrigger === 'imperative') {
      this.lastNavigationMethod = 'link/url'
    } else if (event.navigationTrigger === 'popstate') {
      this.lastNavigationMethod = 'back/forward'
    }
  }

  async sendLogs(lahetysTiedot: LahetysTiedot) {

    try {

      this._ladataanService.aloitaLataaminen()
      const resp = await this._firebaseLemonator.functionsCall<LahetysTiedot, ErrorResponse>('sendLogsToCloudLogging', lahetysTiedot)

      if (!resp || resp.e) {
        return
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._ladataanService.lopetaLataaminen()
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
