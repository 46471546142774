import { Component, OnInit, OnDestroy, ErrorHandler, Input, ChangeDetectionStrategy } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

import { KirjautunutKayttajaService } from './_angular/service/kirjautunut-kayttaja.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from './vaihda-kieli.dialog'

import { AsiakasService } from './_angular/service/asiakas/asiakas.service'
import { KirjanpitoService } from './_angular/service/kirjanpito/kirjanpito.service'

import { Asiakas } from './_jaettu-lemonator/model/asiakas'
import { Kirjanpitaja } from './_jaettu-lemonator/model/kirjanpitaja'
import { AsiakasJaettuService } from './_jaettu-lemonator/service/asiakas-jaettu.service'

import { KirjanpitajanRooli } from './_jaettu/lemonator/model/kirjanpitaja'

import { ChangelogDialog } from './changelog.dialog'
import { KirjanpitajaService } from './_angular/service/kirjanpitaja/kirjanpitaja.service'

import { AsiakkaatDataSourceStateService } from './asiakkaat/listaus/asiakkaat.datasource.service'

import { Aikakone } from './_jaettu-angular/service/aikakone'

import { Subject, Observable, firstValueFrom, combineLatest } from 'rxjs'

import { environment } from '../environments/environment'
import { takeUntil, map, takeWhile } from 'rxjs/operators'
import { FirebaseLemonator } from './_angular/service/firebase-lemonator.service'
import { DateService } from './_shared-core/service/date.service'
import { TyoajanseurantaService } from './_jaettu-lemonator/service/tyoajanseuranta.service'
import { IlmoitinIntegrationEntity } from './_jaettu-lemonator/model/kirjanpito'
import { KirjanpitajanTiimiService } from './_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { AsiakasTyojonoLoadingService } from './_angular/service/asiakas/asiakas-tyojono-loading.service'

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMenuComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _url: string

  // @Output() vasemmanValikonSulkupyynto: EventEmitter<void> = new EventEmitter()
  // @Output() vasemmanValikonSulkupyyntoOma: EventEmitter<'avaa' | 'sulje'> = new EventEmitter()

  @Input() naytaSuper = false
  @Input() naytaYberSuper = false
  @Input() naytaSales = false
  @Input() naytaLisaaAsiakas = false
  @Input() nykyinenAsiakas: Asiakas
  @Input()
  set url(url: string) {
    this._url = url || ''
    if (
      this._url.indexOf('/laskutus') > -1 ||
      this._url.indexOf('/asiakastiedot') > -1 ||
      this._url.indexOf('/kirjanpitotiedot') > -1 ||
      this._url.indexOf('/tuntemistiedot') > -1 ||
      this._url.indexOf('/holvi-import') > -1 ||
      this._url.indexOf('/aineiston-lataus') > -1 ||
      this._url.indexOf('/sopimukset') > -1 ||
      this._url.indexOf('/ajastetut-tyot') > -1 ||
      this._url.indexOf('/lataus-tyot') > -1
    ) {
      this.valittuAlavalikko = 'asetukset'
    } else if (
      this._url.indexOf('/kirjanpito') > -1
    ) {
      this.valittuAlavalikko = 'kirjanpito'
    } else if (
      this._url.indexOf('/tositteet/selaa') > -1 ||
      this._url.indexOf('/laskut/selaa') > -1 ||
      this._url.indexOf('/tositteet/lataa') > -1 ||
      this._url.indexOf('/kayttajat') > -1 ||
      this._url.indexOf('/maksutavat') > -1 ||
      this._url.indexOf('/kirjanpidon-ulkopuoliset-vahennykset') > -1 ||
      this._url.indexOf('/tositteiden-arkisto') > -1 ||
      this._url.indexOf('/laskuasetukset') > -1
    ) {
      this.valittuAlavalikko = 'lemonaid'
    } else if (
      this._url.indexOf('/pankkiyhteydet') > -1 ||
      this._url.indexOf('/tilitapahtumat') > -1 ||
      this._url.indexOf('/yllapito/apixlaskut') > -1 ||
      this._url.indexOf('/veronmaksu') > -1 ||
      this._url.indexOf('/lahetykset') > -1 ||
      this._url.indexOf('/paivasaldot') > -1 ||
      this._url.indexOf('/ytj-tiedot') > -1 ||
      this._url.indexOf('/alusta-kirjanpito') > -1 ||
      this._url.indexOf('/yllapito/massamuikkarit') > -1 ||
      this._url.indexOf('/holvi-tilitapahtumat') > -1 ||
      this._url.indexOf('/yhteisomyynti-ilmoitukset') > -1 ||
      this._url.indexOf('/yllapito/logit') > -1
    ) {
      this.valittuAlavalikko = 'yllapito'
    } else if (
      this._url.indexOf('/zen') > -1 ||
      this._url.indexOf('/asiakkaat') > -1 ||
      this._url.indexOf('/jaettavat-asiakkaat') > -1 ||
      this._url.indexOf('/tilastot') > -1 ||
      this._url.indexOf('/tyoajanseuranta') > -1 ||
      this._url.indexOf('/kirjanpitajan-asetukset') > -1 ||
      this._url === '/' ||
      this._url === ''
    ) {
      this.valittuAlavalikko = 'koti'
    } else {
      this.valittuAlavalikko = 'none'
    }
    // if (this._url.indexOf('/kirjanpito') > -1) {
    //   setTimeout(() => {
    //     this._ngZone.run(() => {
    //       this.vasemmanValikonSulkupyyntoOma.next('sulje')
    //     })
    //   }, 5)
    // } else {
    //   setTimeout(() => {
    //     this._ngZone.run(() => {
    //       this.vasemmanValikonSulkupyyntoOma.next('avaa')
    //     })
    //   }, 5)
    // }
  }
  get url(): string {
    return this._url
  }

  form: UntypedFormGroup

  valittuAlavalikko: 'lemonaid' | 'asetukset' | 'none' | 'koti' | 'kirjanpito' | 'yllapito' = 'lemonaid'
  kirjanpitajaObservable: Observable<Kirjanpitaja>
  kirjanpitajienLista: { avain: string, etunimi: string, sukunimi: string }[] = []
  naytaHolviObservable: Observable<boolean>
  // naytaTyoajanseurantaObservable: Observable<boolean>
  version: string

  lahettamattomatVeroIlmoituksetMaaraObservable: Observable<{ maara: number }>
  lahettamattomatAlvIlmoituksetMaaraObservable: Observable<{ maara: number }>
  lahettamattomatPrhRekisteroinnitMaaraObservable: Observable<{ maara: number }>

  tyojonolatauksetKeskenObservable: Observable<boolean>

  // onkoKirjaamattomiaTuntejaObservable: Observable<boolean>
  // saldotPaivitettyObservable: Observable<string>
  // tyoajanseurantaLinkObservable: Observable<string>

  // public setupCookies() {
  //   this._ladataanService.aloitaLataaminen()
  //   this._auth.auth.currentUser.getIdToken().then(lemonatorToken => {
  //     const payload: { idToken: string } = {
  //       idToken: lemonatorToken
  //     }
  //     return this._httpService.postJsonGetJsonWithCredentials<{ status: 'success' }, { idToken: string }>('/api/1/autentikointi/alustaKeksi', payload, LEMONATOR_HTTPS_API)
  //   }).then(() => {
  //     this._ladataanService.lopetaLataaminen()
  //   }).catch(err => {
  //     this._errorHandler.handleError(err)
  //     this._ladataanService.lopetaLataaminen()
  //   })
  // }

  // public setupCookiesDev() {
  //   this._ladataanService.aloitaLataaminen()
  //   this._auth.auth.currentUser.getIdToken().then(lemonatorToken => {
  //     const payload: { idToken: string } = {
  //       idToken: lemonatorToken
  //     }
  //     return this._httpService.postJsonGetJsonWithCredentials<{ status: 'success' }, { idToken: string }>('/api/1/autentikointi/alustaKeksiDev', payload, LEMONATOR_HTTPS_API)
  //   }).then(() => {
  //     this._ladataanService.lopetaLataaminen()
  //   }).catch(err => {
  //     this._errorHandler.handleError(err)
  //     this._ladataanService.lopetaLataaminen()
  //   })
  // }

  constructor(
    private _errorHandler: ErrorHandler,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _router: Router,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _asiakasService: AsiakasService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService,
    private _asiakasDataSourceStateService: AsiakkaatDataSourceStateService,
    private _kirjanpitoService: KirjanpitoService,
    private _aikakone: Aikakone,
    private _firebase: FirebaseLemonator,
    private _dateService: DateService,
    private _tyoajanseurantaService: TyoajanseurantaService,
    private _asiakasTyojonoLoadingService: AsiakasTyojonoLoadingService
  ) {

    // this.saldotPaivitettyObservable = _firestore.doc<OmaveroSaldokoosteenAikaleima>('omavero-saldot-kooste/viimeisin-aikaleima').valueChanges().pipe(
    //   map(doku => {
    //     if (doku) {
    //       const asDate = this._dateService.timestampToDate(doku.aikaleima)
    //       return this._dateService.muotoilePaivaJaAikaDate(asDate, 'fi')
    //     }
    //     return ''
    //   })
    // )

    this.lahettamattomatPrhRekisteroinnitMaaraObservable = this._firebase.firestoreCollectionGroup<IlmoitinIntegrationEntity>('kirjanpito-prh-rekisteroinnit').where('lahetetty', '==', null).listenSnapshots().pipe(
      map(lahettamattomatQuerySnapshot => {
        return { maara: lahettamattomatQuerySnapshot.length }
      })
    )

    this.lahettamattomatAlvIlmoituksetMaaraObservable = this._firebase.firestoreCollectionGroup<IlmoitinIntegrationEntity>('kirjanpito-alvilmoitukset').where('lahetetty', '==', null).listenSnapshots().pipe(
      map(lahettamattomatQuerySnapshot => {
        return { maara: lahettamattomatQuerySnapshot.length }
      })
    )

    this.lahettamattomatVeroIlmoituksetMaaraObservable = this._firebase.firestoreCollectionGroup<IlmoitinIntegrationEntity>('kirjanpito-veroilmoitus-final').where('lahetetty', '==', null).listenSnapshots().pipe(
      map(lahettamattomatQuerySnapshot => {
        return { maara: lahettamattomatQuerySnapshot.length }
      })
    )

    this.tyojonolatauksetKeskenObservable = this._asiakasTyojonoLoadingService.tyojonolatauksetKeskenObservable

    this.form = new UntypedFormGroup({
      'kirjanpitaja': new UntypedFormControl(null, []),
      'aikakone': new UntypedFormControl(null, [])
    })
    this.form.get('kirjanpitaja').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((value: string) => {
      // this.suljeVasenValikko()
      this._asiakasDataSourceStateService?.rajaaKirjanpitajaAvainObservable?.next(value)
    })
    this.form.get('aikakone').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._aikakone.asetaYliajopäivämäärä(value)
    })

    this.version = environment.version

    this.kirjanpitajaObservable = this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable

    combineLatest([this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotObservable]).pipe(
      takeWhile(([kirjanpitaja, nimitiedot]) => !kirjanpitaja?.uid || !nimitiedot?.length, true), // Stop once both values have arrived
    ).subscribe(async ([kirjanpitaja, nimitiedot]) => {

      if (!kirjanpitaja || !nimitiedot?.length) {
        this.kirjanpitajienLista = []
        return
      }

      nimitiedot.sort((a, b) => {
        if (a.etunimi !== b.etunimi) {
          return a.etunimi.localeCompare(b.etunimi)
        }
        return a.sukunimi.localeCompare(b.sukunimi)
      })

      if (kirjanpitaja.rooli === KirjanpitajanRooli.SUPER) {
        this.kirjanpitajienLista = nimitiedot.filter(nt => !nt.poistettu)
      }

      // if (kirjanpitaja.avain === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
      //   kirjanpitaja.avain === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' // Jon
      // ) {
      //   this.kirjanpitajienLista = nimitiedot
      //   return
      // }

      // const currentNumberDate = this._dateService.currentNumberDate()
      // const tiimi = await this._kirjanpitajanTiimiService.annaKirjanpitajanTiimi(kirjanpitaja.avain, currentNumberDate)

      // const tiimitJotaKirjanpitajaVetaa = await this._kirjanpitajanTiimiService.annaTiimitJotaKirjanpitajaVetaa(kirjanpitaja.avain, _dateService.currentNumberDate())

      // if (!tiimitJotaKirjanpitajaVetaa.length) {
      //   this.kirjanpitajienLista = []
      //   return
      // }

      // const jasenetMap: Map<string, true> = new Map()
      // for (const t of tiimitJotaKirjanpitajaVetaa) {
      //   for (const jasen of t.jasenet) {
      //     jasenetMap.set(jasen.kirjanpitajaAvain, true)
      //   }
      // }

      // this.kirjanpitajienLista = nimitiedot.filter(nt => kirjanpitaja.avain === nt.avain || jasenetMap.has(nt.avain))
      // this.kirjanpitajienLista.push({ avain: 'uekRGWgeLqecXk0Gh8el0s85SEt1', etunimi: 'Lopettaneet', sukunimi: 'asiakkaat', }) // AKA Taneli Testinen

    })

    // this.tyoajanseurantaLinkObservable = this.kirjanpitajaObservable.pipe(
    //   map(kirjanpitaja => {
    //     if (!kirjanpitaja) {
    //       return '/'
    //     }
    //     const currentLocalDate = this._dateService.currentLocalDate()
    //     return '/tyoajanseuranta/' + kirjanpitaja.avain + '/' + currentLocalDate.year + '/' + currentLocalDate.month
    //   })
    // )

    this.naytaHolviObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map((kirjautunutKayttaja) => {
        if (
          kirjautunutKayttaja &&
          (
            kirjautunutKayttaja.rooli === KirjanpitajanRooli.SUPER ||
            kirjautunutKayttaja.rooli === KirjanpitajanRooli.TAVALLINEN_JA_HOLVI
          )
        ) {
          return true
        }
        return false
      })
    )

    // this.naytaTyoajanseurantaObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
    //   map((kirjautunutKayttaja) => {
    //     if (kirjautunutKayttaja?.uid === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Hide from Holvi Zen
    //       return false
    //     }
    //     return true
    //   })
    // )

    this.kirjanpitajaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kirjanpitaja => {
      if (kirjanpitaja) {
        this.form.get('kirjanpitaja').setValue(kirjanpitaja.avain)
      } else {
        this.form.get('kirjanpitaja').setValue(null)
      }
    })

    //   this.onkoKirjaamattomiaTuntejaObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
    //     switchMap(kirjanpitajanTiedot => {
    //       if (!kirjanpitajanTiedot) {
    //         return of<boolean>(false)
    //       }
    //       return this._firebase.firestoreDoc<KirjaamattomiaIndicator>(this._tyoajanseurantaService.annaKirjaamattomiaTuntejaIndicatorUri(kirjanpitajanTiedot.uid)).listen().pipe(
    //         map(val => {
    //           return val?.hasKirjaamattomia
    //         })
    //       )
    //     }),
    //     takeUntil(this._ngUnsubscribe)
    //   )
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  logout() {
    // this.suljeVasenValikko()
    // this.vasemmanValikonSulkupyyntoOma.emit()
    this._ladataanService.aloitaLataaminen()
    this._kirjautunutKayttajaService.logout().then(res => {
      this._router.navigate(['/kirjauduttupois']).then(() => {
        this._ladataanService.lopetaLataaminen()
      }).catch(err => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
      })
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: true
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

  vaihdaAlavalikko(valittu: 'lemonaid' | 'asetukset' | 'koti' | 'kirjanpito' | 'yllapito') {
    this.valittuAlavalikko = valittu
    if (this.nykyinenAsiakas) {
      if (this.valittuAlavalikko === 'lemonaid') {
        this._router.navigate(['asiakkaat', this.nykyinenAsiakas.avain, 'tositteet', 'lataa'])
      } else if (this.valittuAlavalikko === 'kirjanpito') {
        this._router.navigate(['asiakkaat', this.nykyinenAsiakas.avain, 'kirjanpito'])
      } else if (this.valittuAlavalikko === 'asetukset') {
        this._router.navigate(['asiakkaat', this.nykyinenAsiakas.avain, 'asiakastiedot'])
      } else if (this.valittuAlavalikko === 'koti') {
        this._router.navigate(['asiakkaat/uusi-lista'])
      } else if (this.valittuAlavalikko === 'yllapito') {
        this._router.navigate(['asiakkaat', this.nykyinenAsiakas.avain, 'pankkiyhteydet'])
      }
    }
  }

  avaaYllapito() {
    this._asiakasService.asetaNykyinenAsiakas(null)
    this._router.navigate(['yllapito', 'integraatiot-verohallinto'])
  }

  avaaVersiohistoria() {
    this._dialog.open(ChangelogDialog)
  }

  clickKotiin() {
    firstValueFrom(this._asiakasService.nykyinenAsiakasObservable).then(asiakas => {
      if (asiakas) {
        const vastuu = this._asiakasJaettuService.annaNykyinenVastuukirjanpitaja(asiakas)
        if (vastuu && vastuu.kirjanpitajanAvain === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') {
          this._router.navigate(['zen'])
          return
        }
        if (!asiakas.uudessaListassa) {
          this._router.navigate(['asiakkaat'])
          return
        }
      }
      this._router.navigate(['asiakkaat', 'uusi-lista'])
    })
  }

  navigateToTyojonoLatausDetails() {
    this._router.navigate(['asiakkaat', this.nykyinenAsiakas.avain, 'lataus-tyot'])
  }

}
